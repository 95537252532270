


































import _ from 'lodash';
import moment from 'moment-timezone';
import { DataOptions } from 'vuetify';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import { FiltersEventsList } from '@/models/states/filters-state.model';
import { QueryService } from '@/services/query.service';
import DateRangeSelector from '@/components/shared/DateRangeSelector.component.vue';
import StationsSelectTree from '@/components/shared/StationsSelectTree.component.vue';

const now = moment();

@Component({
  name: 'OptionsEventsList',
  components: {
    DateRangeSelector,
    StationsSelectTree,
  },
})
export default class OptionsEventsList extends Vue {
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stations?: Station[];
  @Action('fetchStationLocations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Prop() public tableOptions?: Partial<DataOptions>;
  @Prop({ default: true }) public isOpen!: number;

  public filters: Partial<FiltersEventsList> = {
    startDate: now.clone().subtract(1, 'month').toISOString().substr(0, 10),
    endDate: now.clone().toISOString().substr(0, 10),
    itemsPerPage: 100,
    page: 1,
    stationIds: [],
    textSearch: '',
    magnitudeMin: null,
    magnitudeMax: null,
  };
  public isOpenInner: number | undefined = 0;
  public ignoreFirstTableOptionsSet: boolean = true;

  public mounted() {
    this.applyUrlParams();
    this.fetchStations();
  }

  @Emit()
  public onFiltersChanged(): Partial<FiltersEventsList> {
    return this.filters;
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('tableOptions')
  public onTableOptionsChange() {
    if (this.ignoreFirstTableOptionsSet) {
      this.ignoreFirstTableOptionsSet = false;
      return;
    }
    if (this.tableOptions) {
      this.filters.page = this.tableOptions.page;
      this.filters.itemsPerPage = this.tableOptions.itemsPerPage;
      this.filters.sort = this.getSortParams();
      this.updateQueryParams();
    }
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  public resetPage() {
    this.filters.page = 1;
  }

  public applyFilters() {
    this.onFiltersChanged();
    this.updateQueryParams();
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.filters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  private getSortParams(): string[] {
    return (this.tableOptions?.sortBy ?? []).map((field, index) => {
      const prop = field === 'magnitude' ? 'min_mag' : field;
      const sign = this.tableOptions?.sortDesc?.[index] ? '-' : '';

      return `${sign}${prop}`;
    });
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.filters = this.getQueryParams();
    }

    this.applyFilters();
  }

  private getQueryParams(): Partial<FiltersEventsList> {
    if (!_.isEmpty(this.$route.query)) {
      return {
        endDate: this.$route.query.endDate as string,
        itemsPerPage: this.$route.query.itemsPerPage ? parseInt(this.$route.query.itemsPerPage as string, 10) : undefined,
        page: this.$route.query.page ? parseInt(this.$route.query.page as string, 10) : undefined,
        startDate: this.$route.query.startDate as string,
        stationIds: QueryService.getStations(this.$route) || [],
        textSearch: this.$route.query.textSearch as string,
        magnitudeMin: parseFloat(this.$route.query.magnitudeMin as string) || null,
        magnitudeMax: parseFloat(this.$route.query.magnitudeMax as string) || null,
        sort: QueryService.getSort(this.$route) ?? [],
      };
    }
    return {};
  }
}
