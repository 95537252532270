
































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TreeOptions } from '@/models/app.model';
import { CHANNEL_TREE } from '@/constants/filters.constants';
import { getNameByChannelIdArray } from '@/filters/channel.filter';

@Component({
  name: 'ChannelsTreeSelect',
})
export default class ChannelsTreeSelect extends Vue {
  @Prop() public model!: number[];

  public channelsTree: TreeOptions[] = CHANNEL_TREE;
  public selectedChannelsText: string = '';
  public innerModel: number[] = [];
  public channelsMenu: boolean = false;

  public mounted() {
    this.onModelChange();
  }

  public onChannelSelect(selectedChannels: number[]) {
    this.innerModel = _.sortBy(selectedChannels);
    this.selectedChannelsText = getNameByChannelIdArray(this.innerModel);
    this.$emit('update:model', this.innerModel);
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model) || [];
    this.selectedChannelsText = getNameByChannelIdArray(this.innerModel);
  }
}
