import html2canvas from 'html2canvas';
import { compact } from 'lodash';
import jsPDF from 'jspdf';
import { BlogService } from '@/services/blog.service';

export function publishEvent(
  imageName: string,
  publishData: { title: string; content: string },
  loadingCallback: (loading: boolean) => void,
  successCallback: () => void,
  errorCallback: () => void,
) {
  const element = document.getElementById('map__container');
  const excerpt = publishData.content.split('<br')[0].replace(/<p>/g, '').replace(/<\/p>/g, '');

  if (!element) {
    return;
  }

  html2canvas(element, {
    useCORS: true,
  }).then((featuredImageCanvas: HTMLCanvasElement) => {
    loadingCallback(true);

    featuredImageCanvas.toBlob(
      (blob) => {
        if (!blob) {
          loadingCallback(false);
          return;
        }

        BlogService.addPostWithImage({
          title: publishData.title ? publishData.title : 'No Title Set',
          content: publishData.content,
          excerpt,
          image: blob,
          imageName: `${imageName}.png`,
        }).then(() => {
          loadingCallback(false);
          successCallback();
        }, errorCallback);
      },
      'image/png',
      1.0,
    );
  });
}

export function downloadImage(imageName: string) {
  const element = document.getElementById('capture');
  if (!element) {
    return;
  }

  // @ts-ignore
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  const fillHeightContainers = compact([
    document.getElementsByClassName('fill__height')[0],
    document.getElementsByClassName('fill__height--expanded')[0],
    document.getElementsByClassName('regional-fill__height')[0],
    document.getElementsByClassName('regional-fill__height--expanded')[0],
  ]);

  fillHeightContainers.forEach((container) => {
    container.classList.add('fill__height--expanded--print');
  });

  html2canvas(element, {
    useCORS: true,
    scrollY: -window.scrollY,
  }).then((canvas: HTMLCanvasElement) => {
    canvas.toBlob((blob) => {
      const img = canvas.toDataURL('image/png', 1.0);

      fillHeightContainers.forEach((container) => {
        container.classList.remove('fill__height--expanded--print');
      });

      const widthDoc = doc.internal.pageSize.getWidth();
      const heightDoc = doc.internal.pageSize.getHeight();

      const scale = widthDoc / canvas.width;
      const canvasHeight = canvas.height;
      const scaledHeight = scale * canvasHeight;

      const pageCount = scaledHeight > heightDoc ? Math.ceil(scaledHeight / heightDoc) : 1;
      const padding = 5;

      [...Array(pageCount).keys()].forEach((pageIndex) => {
        if (pageIndex > 0) {
          doc.addPage();
        }
        doc.addImage(img, 'JPEG', padding, -heightDoc * pageIndex + padding, widthDoc - 2 * padding, scaledHeight - 2 * padding);
      });

      doc.save(`${imageName}.pdf`);
    }, 'image/png');
  });
}
