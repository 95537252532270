var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('OptionsEventsList',{staticClass:"mb-3",attrs:{"table-options":_vm.tableOptions},on:{"on-filters-changed":_vm.onFiltersChange,"toggle":_vm.onFiltersToggle}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("List")]),_c('v-tab',[_vm._v("Map")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{class:_vm.isFiltersOpen ? 'fill__height' : 'fill__height--expanded'},[_c('v-data-table',{staticClass:"elevation-10",class:_vm.isFiltersOpen ? 'event-list-table' : 'event-list-table--tight',attrs:{"headers":_vm.headers,"items":_vm.events,"loading":_vm.isLoading,"options":_vm.tableOptions,"footer-props":{ 'items-per-page-options': [20, 50, 100] },"server-items-length":_vm.size,"loading-text":"Loading... Please wait","fixed-header":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.stationIds",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('section',_vm._g(_vm._b({staticStyle:{"max-width":"250px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},'section',attrs,false),on),_vm._l((item.stationIds),function(station){return _c('span',{key:station.stationEventId},[(station.stationEventId)?_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","text":"","link":"","to":{ name: 'event-details', params: { eventId: station.stationEventId } }}},[_vm._v(" "+_vm._s(station.stationEventId)+" ")]):_vm._e()],1)}),0)]}}],null,true)},[_c('span',{staticStyle:{"display":"inline-block","max-width":"200px"}},[_vm._v(_vm._s(item.stationIds.map(function (station) { return station.stationId; }).join(', ')))])])]}},{key:"item.magnitude",fn:function(ref){
var item = ref.item;
return [_c('section',[_vm._v(_vm._s(item.magnitude.min ? item.magnitude.min.toFixed(2) : '--'))])]}},{key:"item.publishable",fn:function(ref){
var item = ref.item;
return [(item.publishable)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.published",fn:function(ref){
var item = ref.item;
return [(item.published)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('section',{staticClass:"table__actions"},[(item.regionalEventId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","icon":"","link":"","to":{ name: 'event-regional', params: { eventId: item.regionalEventId } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-access-point")])],1)]}}],null,true)},[_c('span',[_vm._v("View Regional Event")])]):_vm._e(),(!item.hasOnlySingleStations)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":"","icon":"","link":"","to":{ name: 'event-details', params: { eventId: item.eventId } }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-image-filter-frames")])],1)]}}],null,true)},[_c('span',[_vm._v("View Event")])]):_vm._e()],1)]}}])})],1),(_vm.tab === 1)?_c('v-tab-item',{class:_vm.isFiltersOpen ? 'fill__height' : 'fill__height--expanded'},[_c('RealTimeMap',{attrs:{"map-offset":_vm.isFiltersOpen ? 300 : 203,"suppress-hide":true,"events":_vm.events}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }