import * as Highcharts from 'highcharts';
import { GREEN_COLOR, RED_COLOR } from '@/constants/app.constants';

const xAxisPlotLines: Highcharts.XAxisPlotLinesOptions[] = [
  {
    value: 0,
    dashStyle: 'Solid',
    width: 2,
    color: GREEN_COLOR,
    label: {
      style: {
        color: GREEN_COLOR,
      },
      rotation: 0,
      text: '',
      y: 0,
      x: -55,
      verticalAlign: 'bottom',
    },
    zIndex: -1,
  },
  {
    value: 0,
    dashStyle: 'Solid',
    width: 2,
    color: RED_COLOR,
    label: {
      style: {
        color: RED_COLOR,
      },
      rotation: 0,
      text: '',
      y: 2,
      x: -55,
      verticalAlign: 'top',
    },
  },
];

const paneOptions: any = {
  center: ['50%', '55%'],
  size: '100%',
  startAngle: -120,
  endAngle: 120,
  background: [
    {
      backgroundColor: '#606063',
      innerRadius: '60%',
      outerRadius: '100%',
      shape: 'arc',
    },
  ],
};

const xAxis: Highcharts.XAxisOptions = {
  type: 'datetime',
  tickPixelInterval: 50,
  plotLines: xAxisPlotLines,
  gridLineColor: '#2e2e2f',
  labels: {
    rotation: -20,
  },
};

const HighChartsOptions: Highcharts.Options = {
  chart: {
    animation: {
      duration: 0,
    },
    type: 'line',
    marginRight: 10,
    height: 150,
  },
  title: {
    text: '',
  },
  xAxis,
  yAxis: {
    title: {
      text: '',
    },
    tickPixelInterval: 15,
    gridLineColor: '#2e2e2f',
    plotLines: [],
  },
  tooltip: {
    enabled: true,
    headerFormat: '<b>{series.name}</b><br/>',
    pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}',
    shared: true,
  },
  legend: {
    enabled: false,
    y: 20,
    backgroundColor: 'transparent',
  },
  exporting: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
      borderWidth: 1,
      lineWidth: 1,
    },
  },
  series: [
    {
      states: {
        hover: {
          enabled: false,
        },
      },
      marker: {
        enabled: false,
      },
      type: 'line',
      name: '',
      data: [],
    },
  ],
};

export const HighChartsGaugeOptions: Highcharts.Options = {
  chart: {
    type: 'solidgauge',
    height: 300,
    animation: false,
  },

  title: {
    text: '',
  },

  pane: paneOptions,

  tooltip: {
    enabled: false,
  },

  // the value axis
  yAxis: {
    stops: [
      [0.1, '#55BF3B'], // green
      [0.5, '#DDDF0D'], // yellow
      [0.9, '#DF5353'], // red
    ],
    lineWidth: 0,
    minorTickInterval: null,
    tickAmount: 5,
    title: {
      y: -30,
    },
    labels: {
      y: 16,
    },
  },

  plotOptions: {
    solidgauge: {
      dataLabels: {
        y: -50,
        borderWidth: 0,
        useHTML: true,
      },
    },
  },
};

export default HighChartsOptions;
