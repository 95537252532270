





















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ALL_MESSAGE_TYPES, MESSAGE_TYPES_COLORS } from '@/constants/message-types.constant';

@Component({
  name: 'MessageTypeSelect',
})
export default class MessageTypeSelect extends Vue {
  @Prop() public model!: string[];

  public innerModel: string[] = [];
  public messageTypes: string[] = _.clone(ALL_MESSAGE_TYPES);

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }

  public getMessageColor(type: string) {
    return _.get(MESSAGE_TYPES_COLORS, type);
  }
}
