






































































import moment from 'moment-timezone';
import _ from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { GREY_COLOR, NS_FILTERS_EVENTS, NS_OPEN_PANELS, NS_STATIONS } from '@/constants/app.constants';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { CatalogLocation, CompleteHumanizedEvent, HumanizedEvent } from '@/models/event.model';
import { Station } from '@/models/station.model';
import { ChartConfiguration, PlotLineConfiguration } from '@/models/chart.model';
import { FiltersEvents } from '@/models/states/filters-state.model';
import { Message, MessagesQueryParams, MessageWaveDetected } from '@/models/message.model';
import { roundToDecimal } from '@/filters/number.filter';
import { EventsService } from '@/services/events.service';
import { MessagesService } from '@/services/messages.service';
import { ChartUtils } from '@/utils/chart.utils';
import ChartsGroup from '@/components/charts/ChartsGroup.component.vue';
import ErrorCard from '@/components/shared/ErrorCard.component.vue';
import FooterEventsDetails from '@/components/footers/FooterEventsDetails.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import MessagesTable from '@/components/shared/MessagesTable.component.vue';
import OptionsEventsDetails from '@/components/options-panels/OptionsEventsDetails.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import SplitPanelComponentBase from '@/components/shared/SplitPanelComponentBase';

@Component({
  name: 'EventDetailsView',
  components: {
    ChartsGroup,
    ErrorCard,
    FooterEventsDetails,
    LoadingBar,
    MessagesTable,
    OptionsEventsDetails,
    RealTimeMap,
  },
})
export default class EventDetailsView extends SplitPanelComponentBase {
  @Prop() public eventId!: string;

  @Getter('getStation', { namespace: NS_STATIONS }) station?: Station;
  @Getter('getEventOpenPanels', { namespace: NS_OPEN_PANELS }) vuexOpenPanels?: any;
  @Getter('getFilters', { namespace: NS_FILTERS_EVENTS }) public filters?: FiltersEvents;

  @Action('fetchStation', { namespace: NS_STATIONS }) public fetchStation: any;
  @Action('updateFilters', { namespace: NS_FILTERS_EVENTS }) public updateFilters: any;

  @Mutation('saveEventOpenPanels', { namespace: NS_OPEN_PANELS }) public saveEventOpenPanels!: (payload: {
    eventId: string;
    openPanels: number[];
  }) => void;

  public tab: number = 0;
  public isLoading: boolean = true;
  public event: CompleteHumanizedEvent | null = null;
  public eventMeta: CompleteHumanizedEvent | null = null;
  public totalMessages: number = 0;
  public messages: Message[] = [];
  public channelReadings: ChartConfiguration[] = [];
  public plotLines: PlotLineConfiguration[] = [];
  public eventError: boolean = false;
  public errorParams: any = {};
  public loadEvent: () => void = () => {};

  public mounted() {
    if (this.vuexOpenPanels[this.eventId]) {
      this.readingsOpenPanels = this.vuexOpenPanels[this.eventId];
    }
    this.loadEventMeta();
    this.loadEvent = _.debounce(this.loadEventAction.bind(this), 300);
  }

  @Watch('filters')
  public onFiltersChange() {
    this.loadEvent();
  }

  @Watch('readingsOpenPanels')
  public onReadingsOpenPanelsChange() {
    this.saveEventOpenPanels({ eventId: this.eventId, openPanels: this.readingsOpenPanels });
  }

  public loadEventMeta() {
    EventsService.getMeta(this.eventId)
      .then((event: CompleteHumanizedEvent) => {
        this.eventMeta = event;
        this.fetchStation({ stationId: event.stationId });
        if (this.filters) {
          this.loadMessagesHistory(this.getMessagesServerFilters(this.filters, event));
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.loadEventById();
        }
      });
  }

  public loadEventById() {
    EventsService.getById(this.eventId).then((event: HumanizedEvent) => {
      this.isLoading = false;
      this.eventError = true;
      this.errorParams = {
        displayInterval: event.duration,
        stationIds: _.first(event.stationIds),
        unixTimestamp: event.startTime ? Math.floor(event.startTime / 1000) : 0,
      };
    });
  }

  public loadEventAction() {
    if (!this.filters || !this.eventMeta) {
      return;
    }

    this.saveScrollPosition();

    this.channelReadings = [];
    this.isLoading = true;

    EventsService.getFiltered(this.eventId, this.filters, this.eventMeta).then((event: CompleteHumanizedEvent) => {
      this.isLoading = false;
      if (event.channelReadings.length) {
        this.event = event;
        this.channelReadings = EventsService.extractReadingsFromEvent(this.event, this.filters!);
        this.loadMessagesHistory(this.getMessagesServerFilters(this.filters!, event));
        this.restoreScrollPosition();
      } else {
        this.eventError = true;
      }
    });
  }

  public onZoomChange(event: { start: number; end: number }) {
    this.updateFilters({
      startDate: Math.floor(event.start),
      endDate: Math.ceil(event.end),
    });
  }

  public onHoverChange(timestamp: number) {
    const existingHoverPlotLine = _(this.plotLines).find({ color: GREY_COLOR });
    if (existingHoverPlotLine) {
      existingHoverPlotLine.value = timestamp;
      existingHoverPlotLine.label.text = moment.unix(timestamp / 1000).format('HH:mm:ss.SSS');
    } else {
      this.plotLines.push(ChartUtils.convertTimestampToPlotLine(timestamp));
    }
  }

  public onCatalogLocationAdd(catalogLocation: CatalogLocation) {
    EventsService.saveCatalogLocation(this.eventId, catalogLocation).then(() => {
      this.loadEventMeta();
    });
  }

  public getMessagesServerFilters(filters: FiltersEvents, event: CompleteHumanizedEvent): Partial<MessagesQueryParams> {
    return {
      count: -1,
      start: 0,
      includeRegional: true,
      messageTypes: filters.messageTypes && filters.messageTypes.length ? filters.messageTypes : null,
      sort: { column: 'TIME', ascending: true },
      stationIds: [event.stationId],
      time: {
        from: event.startTime,
        to: event.endTime,
      },
    };
  }

  public get seismogramQueryParams() {
    if (!this.event) {
      return {};
    }

    return {
      bpFilter: this.filters?.bpFilter,
      unixTimestamp: this.event.startTime,
      displayInterval: moment(this.event.endTime).diff(moment(this.event.startTime), 's'),
      channels: this.filters?.channels,
      nodes: this.filters?.nodes,
      messageTypes: this.filters?.messageTypes,
      stationIds: this.event.stationId,
    };
  }

  private loadMessagesHistory(params: Partial<MessagesQueryParams>) {
    if (params.messageTypes === null) {
      this.plotLines = [];
      return;
    }
    MessagesService.query(params).then((response) => {
      this.messages = response.elements;
      this.totalMessages = response.totalCount;
      const plotLines = ChartUtils.convertMessagesToPlotLines(this.messages, true);
      this.plotLines = this.event?.nyasTime ? _.union(plotLines, [ChartUtils.getPlotLineForNyasTime(this.event.nyasTime, this.event)]) : plotLines;

      const sDetectedMessage: MessageWaveDetected = _(this.messages).find({
        type: MESSAGE_TYPES.S_DETECTED,
      }) as MessageWaveDetected;
      if (sDetectedMessage) {
        this.eventMeta = _.extend({}, this.eventMeta, {
          proxyDistance: `${roundToDecimal(sDetectedMessage.proxyDistance.min, 3)} - ${roundToDecimal(sDetectedMessage.proxyDistance.max, 3)}`,
          magnitude: sDetectedMessage.magnitude
            ? `${roundToDecimal(sDetectedMessage.magnitude.min, 3)} - ${roundToDecimal(sDetectedMessage.magnitude.max, 3)}`
            : 'Not determined',
        });
      }
    });
  }
}
