




















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { HumanizedRegionalEvent } from '@/models/event.model';
import { downloadImage, publishEvent } from '@/utils/download.utils';
import { Action } from 'vuex-class';
import { NS_ALERTS } from '@/constants/app.constants';
import { Permissions } from '@/services/permissions.service';
import { EventsService } from '@/services/events.service';

@Component({
  name: 'FooterEventsRegional',
})
export default class FooterEventsRegional extends Vue {
  @Prop({ default: true }) public isCondensedView!: boolean;
  @Prop() public seismogramQueryParams!: (stationId: string) => any;
  @Prop() public event!: HumanizedRegionalEvent | null;
  @Prop() public publishData!: { title: string; content: string };
  @Prop({ default: () => false }) public publishMode?: boolean;
  @Action('addAlert', { namespace: NS_ALERTS }) public addAlert: any;

  public publishLoading = false;
  public isPublisher: boolean = false;

  public mounted() {
    this.isPublisher = Permissions.isPublisher();
  }

  public publishEvent() {
    const loadingCallback = (loading: boolean) => {
      this.publishLoading = loading;
    };

    const successCallback = () => {
      this.addAlert({
        message: 'Successfully published post',
        type: 'success',
      });
      EventsService.updatePublishStatus(this.event?.eventId ?? '').then(() => {
        this.$emit('update-publish-status');
      });
    };

    const errorCallback = () => {
      this.addAlert({
        message: 'Failed to publish post',
        type: 'error',
      });
    };

    publishEvent(this.event?.eventId ?? 'event', this.publishData, loadingCallback, successCallback, errorCallback);
  }

  public enterPublishMode() {
    this.$emit('publish', true);
  }

  public exitPublishMode() {
    this.$emit('publish', false);
  }

  public showAlertedPOIs() {
    this.$emit('onlyAlertedPOIs', true);
  }

  public showAllPOIs() {
    this.$emit('onlyAlertedPOIs', false);
  }

  public downloadImage() {
    downloadImage(this.event?.eventId ?? 'event');
  }
}
