import axios from 'axios';

const WP_URL = 'https://seismicai.com/wp-json/wp/v2';
const USERNAME = 'Rest API';
const PASSWORD = 'hwUQ vL3I vSgZ 1FxV WYDO tthP';
const EVENT_DETECTION_CATEGORY_ID = 82;

const authHeader = {
  Authorization: `Basic ${btoa(process.env.VUE_APP_BLOG_REST_API_KEY)}`,
};

export class BlogService {
  public static addPost(post: { title: string; content: string; excerpt: string; featuredMedia: number }) {
    return axios.post(
      `${WP_URL}/posts`,
      {
        title: post.title,
        content: post.content,
        excerpt: post.excerpt,
        featured_media: post.featuredMedia,
        categories: [EVENT_DETECTION_CATEGORY_ID],
      },
      {
        headers: {
          ...authHeader,
          'Content-Type': 'application/json',
        },
      },
    );
  }

  public static addImage(filename: string, file: Blob) {
    return axios
      .post(`${WP_URL}/media`, file, {
        headers: {
          ...authHeader,
          'Content-Type': 'image/png',
          'Content-Disposition': `attachment; filename="${filename}"`,
        },
      })
      .then((response) => response.data);
  }

  public static addPostWithImage(post: { title: string; content: string; excerpt: string; image: Blob; imageName: string }) {
    return this.addImage(post.imageName, post.image).then((response) =>
      this.addPost({
        ...post,
        content: post.content,
        featuredMedia: response.id,
      }).then((postResponse) => {}),
    );
  }
}
