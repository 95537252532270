import { Route } from 'vue-router';
import _ from 'lodash';

export class QueryService {
  public static getUnit(route: Route): string | null {
    return _.isString(route.query.unit) ? route.query.unit : null;
  }

  public static getFilterType(route: Route): string | null {
    return _.isString(route.query.filterType) ? route.query.filterType : null;
  }

  public static getBPFilter(route: Route): boolean {
    return _.isString(route.query.bpFilter) ? route.query.bpFilter === 'true' : !!route.query.bpFilter;
  }

  public static getDate(route: Route, field: string): number {
    return _.isUndefined(route.query[field]) ? 0 : parseInt(`${route.query[field]}`, 10);
  }

  public static getChannels(route: Route): number[][] | null {
    if (route.query.channels) {
      if (_.isString(route.query.channels)) {
        return [(route.query.channels as string).split(',').map((channel) => parseInt(channel, 10))];
      }
      if (_.isArray(route.query.channels)) {
        return (route.query.channels as string[]).map((channelItem) => {
          if (_.isString(channelItem)) {
            return channelItem.split(',').map((channel) => parseInt(channel, 10));
          }
          if (_.isArray(channelItem)) {
            return channelItem;
          }
          return [];
        });
      }
    }

    return null;
  }

  public static getSimpleChannels(route: Route): number[] | null {
    if (_.isString(route.query.channels)) {
      return [parseInt(route.query.channels, 10)];
    }
    if (_.isArray(route.query.channels)) {
      return (route.query.channels as string[]).map((channel) => parseInt(channel, 10));
    }
    return null;
  }

  public static getNodes(route: Route): number[] | null {
    if (_.isString(route.query.nodes)) {
      return [parseInt(route.query.nodes, 10)];
    }
    if (_.isArray(route.query.nodes)) {
      return (route.query.nodes as string[]).map((node) => parseInt(node, 10));
    }
    return null;
  }

  public static getCustomers(route: Route): string[] | null {
    if (_.isString(route.query.customers)) {
      return [route.query.customers];
    }
    if (_.isArray(route.query.customers)) {
      return route.query.customers.length ? (route.query.customers as string[]) : null;
    }
    return null;
  }

  public static getStations(route: Route): string[] | null {
    if (_.isString(route.query.stationIds)) {
      return [route.query.stationIds];
    }
    if (_.isArray(route.query.stationIds)) {
      return route.query.stationIds as string[];
    }
    return null;
  }

  public static getOptions(route: Route, name: string): string[] | null {
    if (_.isString(route.query[name])) {
      return [route.query[name] as string];
    }
    if (_.isArray(route.query[name])) {
      return route.query[name] as string[];
    }
    return null;
  }

  public static getMessageTypes(route: Route): string[] | null {
    if (_.isString(route.query.messageTypes)) {
      return [route.query.messageTypes];
    }
    if (_.isArray(route.query.messageTypes)) {
      return route.query.messageTypes as string[];
    }
    return null;
  }

  public static getSort(route: Route): string[] | null {
    if (_.isArray(route.query.sort)) {
      return route.query.sort as string[];
    }
    return null;
  }

  public static convertQueryParamsToString(newQueryParams: { [key: string]: any }): { [key: string]: string | string[] } {
    const returnObject = {};
    const getValue = (value: unknown): string | string[] => {
      if (_.isNumber(value) || _.isBoolean(value)) {
        return `${value}` as string;
      }
      if (_.isArray(value)) {
        return value.map((i: string | number | boolean) => (_.isString(i) ? i : `${i}`)) as string[];
      }
      return value as string;
    };

    _(newQueryParams).each((value: unknown, key: string) => {
      _.set(returnObject, key, getValue(value));
    });

    return returnObject;
  }
}
