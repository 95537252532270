







































































































import moment from 'moment-timezone';
import { DataOptions, DataTableHeader } from 'vuetify';
import { Component, Vue } from 'vue-property-decorator';
import { difference, startsWith, trimStart } from 'lodash';
import { Getter } from 'vuex-class';
import { FiltersEventsList } from '@/models/states/filters-state.model';
import { EventSortQueryParams, HumanizedEvent } from '@/models/event.model';
import { EventsService } from '@/services/events.service';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import OptionsEventsList from '@/components/options-panels/OptionsEventsList.component.vue';
import { NS_STATIONS } from '@/constants/app.constants';
import { Station } from '@/models/station.model';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import MessagesTable from '@/components/shared/MessagesTable.component.vue';
import ChartsGroup from '@/components/charts/ChartsGroup.component.vue';
import { Permissions } from '@/services/permissions.service';

const defaultHeaders: DataTableHeader[] = [
  {
    text: 'Name',
    align: 'start',
    sortable: false,
    value: 'name',
  },
  { text: 'Start Date', value: 'startDateTime', sortable: false },
  { text: 'End Date', value: 'endDate', sortable: false },
  { text: 'Duration (seconds)', value: 'duration', sortable: false },
  { text: 'Magnitude', value: 'magnitude', sortable: true },
  { text: 'Stations', value: 'stationIds', sortable: false },
];

const adminHeaders: DataTableHeader[] = [
  { text: 'Publishable', value: 'publishable', width: 50, sortable: false },
  { text: 'Published', value: 'published', width: 50, sortable: false },
];

const actionHeaders: DataTableHeader[] = [{ text: '', value: 'actions', align: 'end', width: 120, sortable: false }];

@Component({
  name: 'EventsListView',
  components: {
    ChartsGroup,
    MessagesTable,
    RealTimeMap,
    LoadingBar,
    OptionsEventsList,
  },
})
export default class EventsListView extends Vue {
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stations?: Station[];
  public isLoading: boolean = true;
  public size: number = 0;
  public events: HumanizedEvent[] = [];
  public headers: DataTableHeader[] = [];
  public tableOptions: Partial<DataOptions> = {
    page: 1,
    itemsPerPage: 100,
  };
  public isFiltersOpen: boolean = true;
  public tab: number = 0;
  public isPublisher: boolean = false;

  public mounted() {
    this.isPublisher = Permissions.isPublisher();

    this.headers = this.isPublisher ? [...defaultHeaders, ...adminHeaders, ...actionHeaders] : [...defaultHeaders, ...actionHeaders];
  }

  public onFiltersChange(filters: FiltersEventsList) {
    this.tableOptions.page = filters.page;
    this.tableOptions.itemsPerPage = filters.itemsPerPage;
    this.loadEvents(filters);
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  private getSortParams(sort: FiltersEventsList['sort']): EventSortQueryParams | undefined {
    if (sort?.length) {
      const field = sort[0];

      const isDesc = startsWith(field, '-');
      const property = isDesc ? trimStart(field, '-') : field;
      const type = isDesc ? 'desc' : 'asc';

      return {
        property,
        type,
      };
    }

    return undefined;
  }

  private loadEvents(filters: FiltersEventsList) {
    this.isLoading = true;

    // The end date is inclusive, so the end of the [from, to] interval should be
    // the last millisecond of the day, not the first.
    const filterEndDate = moment(filters.endDate).add(1, 'day').subtract(1, 'ms').valueOf();

    EventsService.query({
      stationIds: filters.stationIds?.length ? filters.stationIds : null,
      start: filters.itemsPerPage * (filters.page - 1),
      count: filters.itemsPerPage,
      timeRange: {
        from: moment(filters.startDate).valueOf(),
        to: filterEndDate,
      },
      textSearch: filters.textSearch,
      magnitudeMin: filters.magnitudeMin,
      magnitudeMax: filters.magnitudeMax,
      sort: this.getSortParams(filters.sort),
    }).then(
      (response) => {
        const singleStations = (this.stations ?? []).filter((station) => station.type === 'SINGLE').map((station) => station.stationId);
        this.isLoading = false;
        this.size = response.totalCount;
        this.events = response.elements.map((event) => {
          const stationIds = event.stationIds ?? [];
          return {
            ...event,
            stationIds,
            hasOnlySingleStations:
              difference(
                stationIds.map((station) => station.stationId),
                singleStations,
              ).length === 0,
          };
        });
      },
      () => {
        this.isLoading = false;
      },
    );
  }
}
