













































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MessageDetectionSupplementary, MessageSource } from '@/models/message.model';
import { Options } from '@/models/app.model';
import { MessagesService } from '@/services/messages.service';
import StatusIcon from '@/components/shared/StatusIcon.component.vue';

@Component({
  name: 'TableDetailsDialog',
  components: { StatusIcon },
})
export default class TableDetailsDialog extends Vue {
  @Prop({ default: () => 0 }) public message?: MessageSource | MessageDetectionSupplementary;
  @Prop({ default: () => false }) public open?: boolean;

  public displayItems: Options<string>[] = [];
  public tab: number = 0;
  public rawMessageString: string = '';
  public snackbar: boolean = false;

  public mounted() {
    this.onMessageChange();
  }

  public dismiss() {
    this.$emit('onDismiss');
  }

  @Watch('message')
  public onMessageChange() {
    if (this.message) {
      this.displayItems = MessagesService.parseMessage(this.message).filter((item) => !_.includes(['0', '-1', 'null'], item.value));

      if (this.message?.raw) {
        this.rawMessageString = JSON.stringify(this.message.raw);
      } else {
        MessagesService.getRaw(this.message.id).then((rawMessage) => {
          this.rawMessageString = JSON.stringify(rawMessage, null, 3);
        });
      }
    }
  }

  public onCopy() {
    this.snackbar = true;
  }
}
