




























import _ from 'lodash';
import moment from 'moment-timezone';
import * as Highcharts from 'highcharts';
import { Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import HighChartsOptions from '@/constants/highcharts-options.constant';
import { ChartConfiguration, ChartSeriesConfiguration } from '@/models/chart.model';
import { getUnit } from '@/filters/text.filter';

@Component({
  name: 'SeismicChart',
  components: {},
})
export default class SeismicChart extends Vue {
  @Prop({ default: {} }) public chartConfiguration?: ChartConfiguration;
  @Prop({ default: () => false }) public isLoading?: boolean;
  @Prop({ default: false }) public condensedView!: boolean;
  @Prop({ default: 200 }) public height!: number;
  @Prop({ default: () => null }) public min?: number;
  @Prop({ default: () => null }) public max?: number;
  @Prop({ default: () => false }) public hideName?: boolean;
  @Prop({ default: () => [] }) public plotLines?: any[];
  @Prop({ default: () => false }) public canZoom?: boolean;
  @Prop({ default: () => false }) public trackHover?: boolean;
  @Prop({ default: () => true }) public canExpand?: boolean;

  public chartOptions: Highcharts.Options = {};
  public chartOptionsFullscreen: Highcharts.Options = {};
  public dialog: boolean = false;
  public datasetSize: number = 0;

  public mounted() {
    const onHoverCallback = this.trackHover ? _.debounce(this.onHover, 500) : () => {};

    const chartOptions = _.cloneDeep(HighChartsOptions);

    _.set(chartOptions, 'tooltip.headerFormat', '<span style="font-size: 10px">{point.key}</span><br/>.');
    _.set(chartOptions, 'tooltip.pointFormat', '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>.');
    _.set(chartOptions, 'tooltip.positioner', (labelWidth: number, labelHeight: number, point: any) => {
      if (point.plotX < labelWidth) {
        return { x: labelWidth, y: 20 };
      }
      return { x: 0, y: 20 };
    });
    _.set(chartOptions, 'tooltip.formatter', function tooltipFormatter(this: any) {
      return this.points.reduce(function a(s: any, point: any) {
        return `${s}<br/><span style="color:${point.color}">●</span> ${point.series.name}: ${point.y}${getUnit(point.series.options.custom.units)}`;
      }, `<b>${moment.unix(this.x / 1000).format('YYYY-MM-DD HH:mm:ss.SSS ZZ')}</b>`);
    });
    if (chartOptions.legend) {
      chartOptions.legend.enabled = true;
    }
    if (this.chartConfiguration && chartOptions.series) {
      chartOptions.series = [];

      _.set(chartOptions, 'chart.height', this.condensedView ? 170 : this.height);

      _.set(chartOptions, `yAxis.plotLines`, this.chartConfiguration?.yAxisPlotLines || []);
      _.set(chartOptions, `xAxis.plotLines`, this.plotLines);
      if (this.chartConfiguration.series) {
        this.chartConfiguration.series.forEach((series: ChartSeriesConfiguration) => {
          if (chartOptions.series) {
            chartOptions.series.push({
              states: {
                hover: {
                  enabled: false,
                },
              },
              type: 'line',
              marker: {
                enabled: false,
              },
              custom: {
                units: series.units,
              },
              dashStyle: series.hasDisabledData ? 'LongDash' : 'Solid',
              lineWidth: series.hasDisabledData ? 2 : 1,
              name: series.hasDisabledData ? `❗${series.name}` : series.name,
              data: series.data || [],
            });
          }
        });
      }
    }

    _.set(chartOptions, `xAxis.min`, this.min);
    _.set(chartOptions, `xAxis.max`, this.max);
    _.set(chartOptions, `xAxis.labels.rotation`, 0);
    _.set(chartOptions, `xAxis.tickInterval`, this.getTickIntervalValue());

    _.set(chartOptions, `yAxis.tickAmount`, 9);
    _.set(chartOptions, `yAxis.labels.align`, 'left');
    _.set(chartOptions, `yAxis.labels.y`, -2);
    _.set(chartOptions, `yAxis.title.text`, this.condensedView ? this.chartConfiguration?.name.replace('Station', '') : '');
    _.set(chartOptions, `yAxis.title.x`, -15);

    _.set(chartOptions, `legend.enabled`, !this.condensedView);

    if (this.canZoom) {
      _.set(chartOptions, 'chart.zoomType', 'x');
      _.set(chartOptions, 'chart.events.selection', this.onZoomSelection);
    }
    _.set(chartOptions, 'chart.events.click', (event: any) => {
      if (event?.xAxis[0]?.value) {
        onHoverCallback(event.xAxis[0].value);
      }
    });
    _.set(chartOptions, 'chart.events.redraw', (event: any) => {
      this.datasetSize = event.target?.xAxis[0]?.min && event.target?.xAxis[0]?.max ? event.target?.xAxis[0]?.max - event.target?.xAxis[0]?.min : 0;
      _.set(this.chartOptions, `xAxis.tickInterval`, this.getTickIntervalValue());
    });

    this.chartOptions = chartOptions;

    const chartOptionsFullscreen = _.cloneDeep(chartOptions);
    _.set(chartOptionsFullscreen, 'chart.zoomType', null);
    _.set(chartOptionsFullscreen, 'chart.events.selection', null);
    _.set(chartOptionsFullscreen, 'chart.height', null);

    this.chartOptionsFullscreen = chartOptionsFullscreen;
  }

  @Watch('condensedView')
  public onCondensedViewChange() {
    _.set(this.chartOptions, `legend.enabled`, !this.condensedView);
    _.set(this.chartOptions, `chart.height`, this.condensedView ? 170 : this.height);
    _.set(this.chartOptions, `yAxis.title.text`, this.condensedView ? this.chartConfiguration?.name.replace('Station', '') : '');
  }

  @Watch('chartConfiguration', { deep: true })
  public onReadingsChange(chartConfiguration: ChartConfiguration) {
    chartConfiguration.series.forEach((series: ChartSeriesConfiguration) => {
      const cleanName = _.clone(series.name);
      cleanName.replace('❗', '');
      const existingSeries = _(this.chartOptions.series).find({ name: cleanName }) || _(this.chartOptions.series).find({ name: `❗${cleanName}` });
      const indexOf = _.indexOf(this.chartOptions.series, existingSeries);
      _.set(this.chartOptions, `series[${indexOf}].data`, series.data);
      _.set(this.chartOptions, `series[${indexOf}].name`, series.hasDisabledData ? `❗${cleanName}` : cleanName);
      _.set(this.chartOptions, `series[${indexOf}].dashStyle`, series.hasDisabledData ? 'LongDash' : 'Solid');
      _.set(this.chartOptions, `series[${indexOf}].lineWidth`, series.hasDisabledData ? 2 : 1);
    });
  }

  @Watch('plotLines', { deep: true })
  public onPlotLinesChange() {
    _.set(this.chartOptions, `xAxis.plotLines`, this.plotLines);
  }

  @Emit('onZoomChange')
  public onZoomSelection(event: any) {
    return {
      start: _.get(event, 'xAxis[0].min'),
      end: _.get(event, 'xAxis[0].max'),
    };
  }

  @Emit('onHover')
  public onHover(value: number) {
    return value;
  }

  private getTickIntervalValue(): number | undefined {
    const windowWidth = window.innerWidth;
    const cardWidth = Math.round((windowWidth / 12) * 8);
    const numberOfTicks = Math.floor(cardWidth / 100);

    return this.datasetSize === 0 ? 3000 : Math.max(this.datasetSize / numberOfTicks, 2000);
  }
}
