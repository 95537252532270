import moment from 'moment-timezone';
import _ from 'lodash';
import { Message } from '@/models/message.model';
import { MESSAGE_TYPES, MESSAGE_TYPES_COLORS } from '@/constants/message-types.constant';
import { GREY_COLOR, TEAL_COLOR } from '@/constants/app.constants';
import { PlotLineConfiguration } from '@/models/chart.model';
import { getTime } from '@/filters/time.filter';
import { CompleteEvent } from '@/models/event.model';

export class ChartUtils {
  public static convertMessagesToPlotLines(messages: Message[], excludeStationId: boolean = false): PlotLineConfiguration[] {
    return messages
      .filter((message) => message.type !== MESSAGE_TYPES.DETECTION_SUPPLEMENTARY)
      .map((element) =>
        ChartUtils.getPlotLine(
          element.time,
          'Solid',
          _.get(MESSAGE_TYPES_COLORS, element.type),
          3,
          `${getTime(element.time)} ${element.stationId && !excludeStationId ? element.stationId : ''}`,
          { stationId: element.stationId },
        ),
      );
  }

  public static getPlotLineForNyasTime(timestamp: number, event: CompleteEvent): PlotLineConfiguration {
    return ChartUtils.getPlotLine(timestamp, 'Dash', TEAL_COLOR, 3, `NYAS ${getTime(timestamp)}`, { stationId: event.stationId });
  }

  public static convertTimestampToPlotLine(timestamp: number): PlotLineConfiguration {
    return ChartUtils.getPlotLine(timestamp, 'Dash', GREY_COLOR, 1, moment.unix(timestamp / 1000).format('HH:mm:ss.SSS'), {
      stationId: null,
    });
  }

  public static getPlotLine(value: number, dashStyle: string, color: string, width: number, label: string, meta: any = {}): PlotLineConfiguration {
    return {
      meta,
      value,
      dashStyle,
      width,
      color,
      zIndex: -1,
      label: {
        style: {
          color,
        },
        text: label,
      },
    };
  }
}
