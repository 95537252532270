





















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'BPFilterSelect',
})
export default class BPFilterSelect extends Vue {
  @Prop() public model!: boolean;
  @Prop({ default: false }) public disabled!: boolean;

  public innerModel: boolean = true;

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
