






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Message } from '@/models/message.model';
import TableDetailsDialog from '@/components/shared/TableDetailsDialog.component.vue';

@Component({
  name: 'MessagesTable',
  components: {
    TableDetailsDialog,
  },
})
export default class MessagesTable extends Vue {
  @Prop() public messages!: Message[];
  @Prop() public isLoading!: boolean;
  @Prop() public size!: number;
  @Prop() public isFiltersOpen!: boolean;

  public openDialog: boolean = false;
  public dialogRow: Message | null = null;
  public headers: any[] = [
    { text: 'Station', value: 'stationId', sortable: false },
    { text: 'Event Id', value: 'eventId', sortable: false },
    { text: 'Type', value: 'type', sortable: true },
    { text: 'Time', value: 'time', sortable: true },
  ];
  public tableOptions: any = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['time'],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [],
  };

  public onRowClick(row: Message) {
    this.openDialog = true;
    this.dialogRow = row;
  }

  public onDialogDismiss() {
    this.openDialog = false;
    this.dialogRow = null;
  }
}
