

















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/models/app.model';

@Component({
  name: 'NodeSelect',
})
export default class NodeSelect extends Vue {
  @Prop() public model!: string[];
  @Prop() public nodes!: Options<number>[];

  public innerModel: string[] = [];

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
