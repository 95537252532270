



































































import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Options } from '@/models/app.model';
import { FiltersEvents } from '@/models/states/filters-state.model';
import { CompleteHumanizedEvent } from '@/models/event.model';
import { Station } from '@/models/station.model';
import { NS_FILTERS_EVENTS } from '@/constants/app.constants';
import { ALL_MESSAGE_TYPES } from '@/constants/message-types.constant';
import { QueryService } from '@/services/query.service';
import BPFilterSelect from '@/components/shared/BPFilterSelect.component.vue';
import ChannelsTreeSelect from '@/components/shared/ChannelsTreeSelect.component.vue';
import FilterTypeSelect from '@/components/shared/FilterTypeSelect.component.vue';
import MessageTypeSelect from '@/components/shared/MessageTypeSelect.component.vue';
import NodeSelect from '@/components/shared/NodesSelect.component.vue';
import UnitSelect from '@/components/shared/UnitSelect.component.vue';

@Component({
  name: 'OptionsEventsDetails',
  components: {
    BPFilterSelect,
    ChannelsTreeSelect,
    FilterTypeSelect,
    MessageTypeSelect,
    NodeSelect,
    UnitSelect,
  },
})
export default class OptionsEventsDetails extends Vue {
  @Prop() public event!: CompleteHumanizedEvent;
  @Prop() public station!: Station;
  @Prop({ default: true }) public isOpen!: number;
  @Getter('getFilters', { namespace: NS_FILTERS_EVENTS }) public filters?: FiltersEvents;
  @Action('updateFilters', { namespace: NS_FILTERS_EVENTS }) public updateFilters: any;

  public isOpenInner: number | undefined = 0;
  public messageTypes: string[] = _.clone(ALL_MESSAGE_TYPES);
  public innerFilters?: Partial<FiltersEvents> = {};
  public nodes: Options<number>[] = [];

  public mounted() {
    this.applyUrlParams();

    this.nodes = _(this.event.nodeIds)
      .map((nodeId) => ({
        name: `Node ${nodeId}`,
        value: nodeId,
      }))
      .value();

    if (!_.isEmpty(this.filters)) {
      this.onFiltersChange();
    }
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('filters')
  public onFiltersChange() {
    this.innerFilters = _.cloneDeep(this.filters);
    this.updateQueryParams();
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  public applyFilters(filters: Partial<FiltersEvents> | undefined = this.innerFilters) {
    this.updateFilters(filters);
  }

  public resetZoom() {
    this.applyFilters({
      startDate: 0,
      endDate: 0,
    });
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.innerFilters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.applyFilters(this.getQueryParams());
    }
  }

  private getQueryParams() {
    return _.isEmpty(this.$route.query)
      ? {}
      : {
          unit: QueryService.getUnit(this.$route),
          filterType: QueryService.getFilterType(this.$route),
          bpFilter: QueryService.getBPFilter(this.$route),
          channels: QueryService.getSimpleChannels(this.$route) || this.event.channelIds,
          endDate: QueryService.getDate(this.$route, 'endDate'),
          messageTypes: QueryService.getMessageTypes(this.$route),
          nodes: QueryService.getNodes(this.$route) || this.event.nodeIds,
          startDate: QueryService.getDate(this.$route, 'startDate'),
        };
  }
}
