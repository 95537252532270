












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'ErrorCard',
})
export default class ErrorCard extends Vue {
  @Prop() public errorParams!: any;
}
