import _ from 'lodash';
import axios from 'axios';
import { ReadingsRealtimeRequest, ReadingsRealtimeResponse, ReadingsRequest, ReadingsResponse } from '@/models/response.model';
import { Station, StationReading } from '@/models/station.model';
import { roundToDecimals } from '@/utils/math.utils';
import { FiltersRealtime } from '@/models/states/filters-state.model';

export class ReadingService {
  public static query(params: ReadingsRequest): Promise<ReadingsResponse> {
    return axios.post<ReadingsResponse>(`/readings/query`, params).then((response) => response.data);
  }

  public static realtimeQuery(params: ReadingsRealtimeRequest): Promise<ReadingsRealtimeResponse> {
    return axios.post<ReadingsRealtimeResponse>(`/readings/realtime/query`, params).then((response) => response.data);
  }

  public static getAppendedReadings(existingReadings: StationReading[], stations: Station[], newStationData: any, minTime: number): StationReading[] {
    return existingReadings.map((station) => {
      const foundStation = _(stations).find({ stationId: station.stationId });
      if (foundStation) {
        const nodeId = foundStation.meta.mainNodeId;
        const channelId = foundStation.meta.mainChannelId;
        const path = `${station.stationId}.nodes.${nodeId}.channels.${channelId}`;
        const dataPath = `${path}.sensorReadings`;
        const statusPath = `${path}.channelStatuses`;
        const newData = _.get(newStationData, dataPath, []).map((data: any) => [data.time, data.value && roundToDecimals(data.value, 6)]);

        const newStatusData = _.get(newStationData, statusPath, []);

        // const minTime = this.getBackIntervalMin();
        if (newData.length > 0) {
          const combinedData = _(station.data)
            // exclude data points not in the desired interval or that appear in the
            // newly fetched data again due to padding reasons
            .reject((item) => item[0] <= minTime || item[0] >= newData[0][0])
            .union(newData)
            .value();
          const combinedStatuses = _(station.statuses)
            .reject((item) => item.from <= minTime)
            .union(newStatusData)
            .value();

          _.set(station, 'data', combinedData);
          _.set(station, 'statuses', combinedStatuses);
          _.set(station, 'hasDisabledData', combinedStatuses.filter((s: any) => s.channelStatus !== 'ENABLED').length > 0);
        }
      }

      return station;
    });
  }

  public static generateStationReadings(stations: Station[], filters?: FiltersRealtime): StationReading[] {
    const filteredStations = _.get(filters, 'stationIds', []);
    return stations.map((station) => ({
      data: [],
      statuses: [],
      stationId: station.stationId,
      stationName: station.stationName,
      stationStatus: station.healthStatus,
      fullStation: station,
      hidden: !_.includes(filteredStations, station.stationId),
    }));
  }
}
