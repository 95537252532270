















































import _ from 'lodash';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ChartConfiguration, PlotLineConfiguration } from '@/models/chart.model';
import SeismicChart from '@/components/charts/SeismicChart.component.vue';

@Component({
  name: 'ChartsGroup',
  components: {
    SeismicChart,
  },
})
export default class ChartsGroup extends Vue {
  @Prop() public configurations!: ChartConfiguration[];
  @Prop() public plotLines!: any[];
  @Prop({ default: false }) public condensedView!: boolean;
  @Prop({ default: 0 }) public dataSetSize!: number;
  @Prop({ default: false }) public trackHover!: boolean;
  @Prop({ default: false }) public filterPlotLines!: boolean;
  @Prop({ default: null }) public min?: number;
  @Prop({ default: null }) public max?: number;
  @Prop({ default: () => [0] }) public openPanels!: number[];

  public innerOpenPanels: number[] = [0];

  public mounted() {
    this.innerOpenPanels = this.openPanels;
  }

  public isOpen(value: number): boolean {
    return _.includes(this.innerOpenPanels, value);
  }

  @Watch('openPanels')
  public onOpenPanelsChange() {
    this.innerOpenPanels = this.openPanels;
  }

  @Watch('innerOpenPanels')
  public onOpenChannelPanels() {
    this.$emit('update:openPanels', this.innerOpenPanels);
    this.triggerResize();
  }

  @Emit('onZoomChange')
  public onZoomChange(event: any) {
    return event;
  }

  @Emit('onHover')
  public onHover(event: any) {
    return event;
  }

  public getPlotLines(configuration: ChartConfiguration) {
    if (!this.filterPlotLines) {
      return this.plotLines;
    }

    return this.plotLines.filter((plotLine: PlotLineConfiguration) => {
      return plotLine.meta.stationId === configuration.key || plotLine.meta.stationId === null;
    });
  }

  private triggerResize() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }
}
