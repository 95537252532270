
























































import _ from 'lodash';
import moment from 'moment-timezone';
import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FiltersEventsRegional } from '@/models/states/filters-state.model';
import { HumanizedRegionalEvent } from '@/models/event.model';
import { NS_FILTERS_EVENTS_REGIONAL } from '@/constants/app.constants';
import { QueryService } from '@/services/query.service';
import FilterTypeSelect from '@/components/shared/FilterTypeSelect.component.vue';
import UnitSelect from '@/components/shared/UnitSelect.component.vue';

@Component({
  name: 'OptionsEventsRegional',
  components: {
    FilterTypeSelect,
    UnitSelect,
  },
})
export default class OptionsEventsRegional extends Vue {
  @Prop() public event!: HumanizedRegionalEvent;
  @Prop({ default: true }) public isOpen!: number;
  @Getter('getFilters', { namespace: NS_FILTERS_EVENTS_REGIONAL }) public filters?: FiltersEventsRegional;
  @Action('updateFilters', { namespace: NS_FILTERS_EVENTS_REGIONAL }) public updateFilters: any;

  public isOpenInner: number | undefined = 0;
  public innerFilters: Partial<FiltersEventsRegional> = {};

  public mounted() {
    this.applyUrlParams();

    if (this.innerFilters.observedTime === 0) {
      this.innerFilters.observedTime = this.event.endTime;
      if (this.filters) {
        this.filters.observedTime = this.event.endTime;
      }
    }

    if (!_.isEmpty(this.filters)) {
      this.onFiltersChange();
    }
  }

  @Watch('isOpen')
  public onOpenChange() {
    this.isOpenInner = this.isOpen ? 0 : undefined;
  }

  @Watch('filters')
  public onFiltersChange() {
    if (this.filters) {
      if (this.filters.observedTime === 0) {
        this.filters.observedTime = this.event.endTime;
      }
      this.innerFilters = _.cloneDeep(this.filters);
      this.updateQueryParams();
    }
  }

  @Watch('$route')
  public onRouteChange() {
    this.applyUrlParams();
  }

  public getTimelineLabel() {
    return `Time: ${moment(this.innerFilters.observedTime).format('HH:mm:ss SSS')}`;
  }

  public applyFilters(filters: Partial<FiltersEventsRegional> | undefined = this.innerFilters) {
    this.updateFilters(filters);
  }

  public resetZoom() {
    this.applyFilters({
      startDate: 0,
      endDate: 0,
    });
  }

  public updateQueryParams() {
    const newQueryParams = _.omit(this.innerFilters, ['date', 'time']);
    const queryParams = _.omit(this.getQueryParams(), ['date', 'time']);

    if (_.isEqual(newQueryParams, queryParams)) {
      return;
    }

    this.$router
      .push(
        _.extend({}, this.$route, {
          query: QueryService.convertQueryParamsToString(newQueryParams),
        }),
      )
      .catch(() => {});
  }

  private applyUrlParams() {
    if (!_.isEmpty(this.$route.query)) {
      this.applyFilters(this.getQueryParams());
    }
  }

  private getQueryParams() {
    if (!_.isEmpty(this.$route.query)) {
      const observedTime = _.isUndefined(this.$route.query.observedTime) ? this.event.endTime : parseInt(`${this.$route.query.observedTime}`, 10);
      return {
        unit: QueryService.getUnit(this.$route),
        filterType: QueryService.getFilterType(this.$route),
        bpFilter: QueryService.getBPFilter(this.$route),
        endDate: QueryService.getDate(this.$route, 'endDate'),
        startDate: QueryService.getDate(this.$route, 'startDate'),
        observedTime,
      };
    }
    return {};
  }
}
