














import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/models/app.model';
import { UNITS } from '@/constants/readings.constants';

@Component({
  name: 'UnitSelect',
})
export default class UnitSelect extends Vue {
  @Prop() public model!: string;

  public units: Options<string>[] = _.cloneDeep(UNITS).filter((unit) => !unit.hidden);
  public innerModel: string = '';

  public mounted() {
    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
