


















import _ from 'lodash';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Options } from '@/models/app.model';
import { FILTER_TYPE, FILTER_TYPES } from '@/constants/readings.constants';
import { EventOriginalDataFilter } from '@/models/event.model';

@Component({
  name: 'FilterTypeSelect',
})
export default class FilterTypeSelect extends Vue {
  @Prop() public model!: string;
  @Prop() public originalDataFilter!: EventOriginalDataFilter;

  public filterTypes: Options<string>[] = [];
  public innerModel: string = '';
  public disabled: boolean = false;
  public staticValue: string = '';

  public mounted() {
    const filterTypes = FILTER_TYPES(this.originalDataFilter?.low, this.originalDataFilter?.high);

    if (this.originalDataFilter && this.originalDataFilter?.filterType !== FILTER_TYPE.NONE) {
      this.filterTypes = _(filterTypes).filter({ value: this.originalDataFilter?.filterType }).value();
      this.staticValue = _.get(_.first(this.filterTypes), 'name', '');
    } else {
      this.filterTypes = filterTypes;
    }

    this.onModelChange();
  }

  @Watch('model')
  public onModelChange() {
    this.innerModel = _.clone(this.model);
  }
}
