







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CompleteHumanizedEvent } from '@/models/event.model';
import { downloadImage } from '@/utils/download.utils';

@Component({
  name: 'FooterEventsDetails',
})
export default class FooterEventsDetails extends Vue {
  @Prop({ default: true }) public isCondensedView!: boolean;
  @Prop() public seismogramQueryParams!: boolean;
  @Prop() public event!: CompleteHumanizedEvent | null;

  public downloadImage() {
    downloadImage(this.event?.eventId ?? 'event');
  }
}
