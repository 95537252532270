























































































































import moment from 'moment-timezone';
import _, { cloneDeep, take, uniq } from 'lodash';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { VueEditor } from 'vue2-editor';
import { GREY_COLOR, INTENSITY_COLORS, NS_FILTERS_EVENTS_REGIONAL, NS_OPEN_PANELS, NS_STATIONS } from '@/constants/app.constants';
import { MESSAGE_TYPES } from '@/constants/message-types.constant';
import { CatalogLocation, HumanizedRegionalEvent, StationEventRef } from '@/models/event.model';
import { Station } from '@/models/station.model';
import { ChartConfiguration, PlotLineConfiguration } from '@/models/chart.model';
import { FiltersEventsRegional } from '@/models/states/filters-state.model';
import { Message } from '@/models/message.model';
import { ChartUtils } from '@/utils/chart.utils';
import { EventsService } from '@/services/events.service';
import { MessagesService } from '@/services/messages.service';
import ChartsGroup from '@/components/charts/ChartsGroup.component.vue';
import FooterEventsRegional from '@/components/footers/FooterEventsRegional.component.vue';
import LoadingBar from '@/components/shared/LoadingBar.component.vue';
import MessagesTable from '@/components/shared/MessagesTable.component.vue';
import OptionsEventsRegional from '@/components/options-panels/OptionsEventsRegional.component.vue';
import RealTimeMap from '@/components/real-time/RealTimeMap.component.vue';
import SplitPanelComponentBase from '@/components/shared/SplitPanelComponentBase';
import { ReadingService } from '@/services/reading.service';
import { getNameByChannelId } from '@/filters/channel.filter';
import { StationType } from '@/constants/station.constants';

@Component({
  name: 'EventRegionalView',
  components: {
    ChartsGroup,
    FooterEventsRegional,
    LoadingBar,
    MessagesTable,
    OptionsEventsRegional,
    RealTimeMap,
    VueEditor,
  },
})
export default class EventRegionalView extends SplitPanelComponentBase {
  @Prop() public eventId!: string;
  @Getter('getStationLocations', { namespace: NS_STATIONS }) stations?: Station[];
  @Getter('getRegionalEventOpenPanels', { namespace: NS_OPEN_PANELS }) vuexOpenPanels?: any;
  @Getter('getFilters', { namespace: NS_FILTERS_EVENTS_REGIONAL })
  public filters?: FiltersEventsRegional;

  @Action('fetchStationLocations', { namespace: NS_STATIONS }) public fetchStations: any;
  @Action('updateFilters', { namespace: NS_FILTERS_EVENTS_REGIONAL }) public updateFilters: any;

  @Mutation('saveRegionalEventOpenPanels', { namespace: NS_OPEN_PANELS }) public saveRegionalEventOpenPanels!: (payload: {
    eventId: string;
    openPanels: number[];
  }) => void;

  public tab: number = 0;
  public isLoading: boolean = true;
  public event: HumanizedRegionalEvent | null = null;
  public eventMeta: HumanizedRegionalEvent | null = null;
  public mapMessages: Message[] = [];
  public messages: Message[] = [];
  public totalMessages: number = 0;
  public readings: ChartConfiguration[] = [];
  public plotLines: PlotLineConfiguration[] = [];
  public chartMin: number = 0;
  public chartMax: number = 0;
  public filteredStations: Station[] = [];
  public triggeredStations: Station[] = [];
  public loadEvent: () => void = () => {};
  public impactedStationIds: string[] = [];
  public inPublishMode: boolean = false;
  public mapIncrement: number = 0;
  public publishEvent: { title: string; content: string } = {
    title: '',
    content: '',
  };
  public INTENSITY_COLORS: { [key: string]: string } = INTENSITY_COLORS;
  public intensityArray = Array.from(Array(12).keys());
  public eventLoading: boolean = false;

  public mounted() {
    if (this.vuexOpenPanels[this.eventId]) {
      this.readingsOpenPanels = this.vuexOpenPanels[this.eventId];
    }
    this.loadEvent = _.debounce(this.loadEventAction.bind(this), 300);
    this.fetchStations();
  }

  @Watch('stations')
  public onStationsLoaded() {
    if (!this.eventLoading) {
      this.loadEventMeta();
    }
  }

  @Watch('filters')
  public onFiltersChange() {
    this.loadEvent();
    if (this.filters?.observedTime) {
      this.onHoverChange(this.filters?.observedTime);
    }
  }

  @Watch('readingsOpenPanels')
  public onReadingsOpenPanelsChange() {
    this.saveRegionalEventOpenPanels({ eventId: this.eventId, openPanels: this.readingsOpenPanels });
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public setPublishMode(toggle: boolean) {
    this.inPublishMode = toggle;

    const locationName = this.event?.pointsOfInterest?.[0].name ?? '--- NO LOCATION NAME SET ---';
    const magnitude = this.event?.parsedMagnitudeSimple ?? '--- NO MAGNITUDE SET ---';
    const date = this.event?.parsedOriginTimeInverse ?? '--- NO DATE SET ---';
    const sLeadTime = this.event?.pointsOfInterest[0].sLeadTime ?? null;
    const location = this.event?.parsedLocation ?? '--- NO LOCATION SET ---';
    let sLeadTimeText = '--- NO LEAD TIME SET ---';
    if (sLeadTime !== null) {
      sLeadTimeText = sLeadTime < 0 ? `${locationName} was in the blind zone` : `${sLeadTime} sec`;
    }

    this.publishEvent = {
      title: `SeismicAI detects magnitude ${magnitude} earthquake near ${locationName}`,
      content: `<p>SeismicAI pilot system detected and issued an alert on a magnitude ${magnitude} earthquake near ${locationName}, which took place on ${date} (UTC)</p><br/>`,
    };

    this.publishEvent.content += '<ul>';
    this.publishEvent.content += `<li>Magnitude: <strong>${magnitude}</strong></li>`;
    this.publishEvent.content += `<li>Date: <strong>${date} (UTC)</strong></li>`;
    this.publishEvent.content += `<li>Epicenter: <strong>${location}</strong></li>`;
    this.publishEvent.content += `<li>Lead time for ${locationName}: <strong>${sLeadTimeText}</strong></li>`;
    this.publishEvent.content += '</ul>';

    setTimeout(() => {
      this.mapIncrement += 1;
    }, 100);
  }

  public updatePublishStatus() {
    if (this.event) {
      this.$set(this.event, 'published', true);
    }
  }

  public loadEventMeta() {
    this.eventLoading = true;
    EventsService.getRegionalMeta(this.eventId)
      .then((event: HumanizedRegionalEvent) => {
        this.eventLoading = false;
        this.eventMeta = event;
        const eventStations = this.eventMeta.stationEvents.map((stationEvent) => stationEvent.stationId);
        const eventRegions = _.uniq(this.stations?.filter((station) => eventStations.includes(station.stationId)).map((station) => station.regionId));
        this.filteredStations = this.stations ? this.stations?.filter((station) => eventRegions.includes(station.regionId)) : [];
        this.triggeredStations = this.stations ? this.stations?.filter((station) => eventStations.includes(station.stationId)) : [];
        this.loadMessagesHistory();
      })
      .catch(() => {
        this.eventLoading = false;
      });
  }

  public loadEventAction() {
    if (!this.filters || !this.eventMeta) {
      return;
    }

    this.saveScrollPosition();

    const alreadyHaveReadings = this.readings.length > 0;

    if (!alreadyHaveReadings) {
      this.readings = [];
    }

    this.impactedStationIds = [];
    this.isLoading = true;

    EventsService.getRegionalFiltered(this.eventId, this.filters, this.eventMeta, !alreadyHaveReadings)
      .then((event: HumanizedRegionalEvent) => {
        this.isLoading = false;
        this.event = event;
        this.eventMeta = event;
        if (this.filters) {
          this.chartMin = this.filters.startDate !== 0 ? this.filters.startDate : this.event.startTime;
          this.chartMax = this.filters.endDate !== 0 ? this.filters.endDate : this.event.endTime;
        }

        this.mapMessages = _(this.event.lastLocalMessagesByStationAndType)
          .map((stations) =>
            _(stations)
              .map((message) => message)
              .value(),
          )
          .flatten()
          .union([this.event.lastSourceMessage])
          .union(this.event.vidaMessages ?? [])
          .compact()
          .sortBy(['time'])
          .value();

        // this.impactedStationIds = _.uniq(this.mapMessages.map((message) => message.stationId));
        this.impactedStationIds = this.event.lastSourceMessage?.triggeredStationIds ?? [];

        if (!alreadyHaveReadings) {
          const stationsWithReadings = this.event.readings.map((reading) => reading.stationId);
          const singleStations = take(
            this.event.stationEvents
              .filter((stationEvent) => stationEvent.type === StationType.SINGLE)
              .filter((stationEvent) => !stationsWithReadings.includes(stationEvent.stationId)),
            50,
          );

          this.readings = EventsService.extractReadingsFromRegionalEvent(this.event);

          this.loadSingleStationReadings(singleStations);
        }

        this.restoreScrollPosition();
        if (this.filters?.observedTime) {
          this.onHoverChange(this.filters?.observedTime);
        }
      })
      .catch((error) => {
        if (error.response.data === 'Query interval is too large. Should be maximum one hour') {
          this.updateFilters({
            observedTime: 0,
          });
        }
      });
  }

  public loadSingleStationReadings(singleStations: StationEventRef[]) {
    const emptyReadings = singleStations.map((station) => ({
      name: `Station ${station.stationId}`,
      key: station.stationId,
      series: [],
      loading: true,
      error: false,
    }));

    this.readings = this.readings.concat(emptyReadings);

    ReadingService.query({
      count: 100,
      from: this.chartMin,
      to: this.chartMax,
      timeGrouping: {
        unit: 'MILLIS',
        value: 100,
      },
      stationIds: singleStations.map((station) => station.stationId),
      nodes: [0],
      channels: uniq(singleStations.map((station) => station.mainChannelId)),
      preferredUnits: 'VOLTS',
    })
      .then((singlesReadings) => {
        const result = _(singlesReadings.stations)
          .map((station, stationId: string) => {
            const mainChannelId = _.get(_(this.stations).find({ stationId }), 'mainChannelId', 0);
            const mainNodeId = _.get(_(this.stations).find({ stationId }), 'mainNodeId', 0);
            const readings = _.get(station, `nodes[${mainNodeId}].channels[${mainChannelId}].sensorReadings`, []);

            return {
              name: `Station ${stationId}`,
              key: stationId,
              series: [
                {
                  name: `Node ${mainNodeId}, Channel ${getNameByChannelId(mainChannelId)}`,
                  key: stationId,
                  data: readings.map((reading: any) => [reading.time, reading.value]),
                  units: '',
                },
              ],
            };
          })
          .value();

        this.readings = this.readings.map((existingReading) => {
          const newReading = result.find((reading) => reading.key === existingReading.key);
          if (newReading) {
            return newReading;
          }
          return existingReading;
        });
      })
      .catch(() => {
        this.readings = this.readings.map((existingReading) => {
          return singleStations.map((station) => station.stationId).includes(existingReading.key as string)
            ? { ...existingReading, loading: false, error: true }
            : existingReading;
        });
      });
  }

  public onCatalogLocationAdd(catalogLocation: CatalogLocation) {
    const apiCatalogLocation = cloneDeep(catalogLocation);

    apiCatalogLocation.time = moment(`${apiCatalogLocation.date} ${apiCatalogLocation.dateTime}`, 'YYYY-MM-DD HH:mm:ss').valueOf();

    delete apiCatalogLocation.date;
    delete apiCatalogLocation.dateTime;

    EventsService.saveCatalogLocation(this.eventId, apiCatalogLocation).then(() => {
      this.loadEventMeta();
    });
  }

  public onCatalogLocationRemove(catalogLocation: CatalogLocation) {
    if (!catalogLocation.id) {
      return;
    }
    EventsService.removeCatalogLocation(this.eventId, catalogLocation.id).then(() => {
      this.loadEventMeta();
    });
  }

  public onZoomChange(event: { start: number; end: number }) {
    this.updateFilters({
      startDate: Math.floor(event.start),
      endDate: Math.ceil(event.end),
    });
  }

  public onHoverChange(timestamp: number) {
    const existingHoverPlotLine = _(this.plotLines).find({ color: GREY_COLOR });
    if (existingHoverPlotLine) {
      existingHoverPlotLine.value = timestamp;
      existingHoverPlotLine.label.text = moment.unix(timestamp / 1000).format('HH:mm:ss.SSS');
    } else {
      this.plotLines.push(ChartUtils.convertTimestampToPlotLine(timestamp));
    }

    if (this.filters?.observedTime !== timestamp) {
      this.updateFilters({
        observedTime: timestamp,
      });
    }
  }

  public seismogramQueryParams(stationId: string) {
    if (!this.event) {
      return {};
    }
    return {
      channels: null,
      bpFilter: this.filters?.bpFilter,
      unixTimestamp: this.event.startTime,
      displayInterval: moment(this.event.endTime).diff(moment(this.event.startTime), 's'),
      stationIds: stationId,
    };
  }

  private loadMessagesHistory() {
    if (!this.eventMeta) {
      return;
    }

    MessagesService.query(MessagesService.getQueryPramsForRegionalEvent(this.eventMeta)).then((response) => {
      this.messages = response.elements;
      this.totalMessages = response.totalCount;
      this.plotLines = ChartUtils.convertMessagesToPlotLines(
        this.messages.filter((message) =>
          _.includes(
            [
              MESSAGE_TYPES.S_DETECTED,
              MESSAGE_TYPES.S_DETECTED_SINGLE,
              MESSAGE_TYPES.P_DETECTED,
              MESSAGE_TYPES.P_DETECTED_SINGLE,
              MESSAGE_TYPES.EVENT_ENDED,
              MESSAGE_TYPES.EVENT_ENDED_SINGLE,
              MESSAGE_TYPES.VIDA,
            ],
            message.type,
          ),
        ),
      );
    });
  }
}
