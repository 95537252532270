











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StatusIcon',
})
export default class StatusIcon extends Vue {
  @Prop({ default: () => '' }) public status?: string;
}
