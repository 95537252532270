import _ from 'lodash';
import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class SplitPanelComponentBase extends Vue {
  public isFiltersOpen: boolean = true;
  public scrollEventEnabled: boolean = false;
  public readingsOpenPanels: number[] = [0];
  public extraReadingsOpenPanels: number[] = [0];
  public previousScrollTop: number = 0;
  public isCondensedView: boolean = false;
  public savedReadingsOpenPanels: number[] = [];
  public savedExtraReadingsOpenPanels: number[] = [];

  public destroyed() {
    this.removeScrollListener();
  }

  public addScrollListener() {
    if (!this.$refs.chartsGroup || this.scrollEventEnabled) {
      return;
    }

    if ((this.$refs.chartsGroup as Vue).$el) {
      (this.$refs.chartsGroup as Vue).$el.addEventListener('scroll', this.handleScroll);
    } else {
      (this.$refs.chartsGroup as Element).addEventListener('scroll', this.handleScroll);
    }
    this.scrollEventEnabled = true;
  }

  public removeScrollListener() {
    if (!this.$refs.chartsGroup || !this.scrollEventEnabled) {
      return;
    }

    if ((this.$refs.chartsGroup as Vue).$el) {
      (this.$refs.chartsGroup as Vue).$el.removeEventListener('scroll', this.handleScroll);
    } else {
      (this.$refs.chartsGroup as Element).removeEventListener('scroll', this.handleScroll);
    }
  }

  public handleScroll() {
    if (!this.$refs.chartsGroup) {
      return;
    }

    if ((this.$refs.chartsGroup as Vue).$el) {
      if ((this.$refs.chartsGroup as Vue).$el.scrollTop > 100 && this.isFiltersOpen) {
        this.isFiltersOpen = false;
      }
      return;
    }

    if ((this.$refs.chartsGroup as Element).scrollTop > 100 && this.isFiltersOpen) {
      this.isFiltersOpen = false;
    }
  }

  public onFiltersToggle(isOpen: number) {
    this.isFiltersOpen = isOpen === 0;
  }

  public onCondensedToggle() {
    this.isCondensedView = !this.isCondensedView;

    if (this.isCondensedView) {
      this.isFiltersOpen = false;

      this.savedReadingsOpenPanels = _.clone(this.readingsOpenPanels);
      this.savedExtraReadingsOpenPanels = _.clone(this.extraReadingsOpenPanels);
    } else {
      this.readingsOpenPanels = _.clone(this.savedReadingsOpenPanels);
      this.extraReadingsOpenPanels = _.clone(this.savedExtraReadingsOpenPanels);
    }
  }

  public expandAllCharts(readingsLength: number, extraReadingsLength?: number) {
    this.readingsOpenPanels = Array.from(Array(readingsLength).keys());
    if (extraReadingsLength) {
      this.extraReadingsOpenPanels = Array.from(Array(extraReadingsLength).keys());
    }
  }

  public saveScrollPosition() {
    if (!this.$refs.chartsGroup) {
      return;
    }
    if ((this.$refs.chartsGroup as Vue).$el) {
      this.previousScrollTop = (this.$refs.chartsGroup as Vue).$el.scrollTop;
    } else {
      this.previousScrollTop = (this.$refs.chartsGroup as Element).scrollTop;
    }
  }

  public restoreScrollPosition() {
    setTimeout(() => {
      this.addScrollListener();

      if (!this.$refs.chartsGroup) {
        return;
      }

      if ((this.$refs.chartsGroup as Vue).$el) {
        (this.$refs.chartsGroup as Vue).$el.scrollTop = this.previousScrollTop;
      } else {
        (this.$refs.chartsGroup as Element).scrollTop = this.previousScrollTop;
      }
    }, 700);
  }
}
